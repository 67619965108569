// Aca_Groups.js
import React, { useEffect ,Suspense } from 'react';
import BreadCrumps from '../layout/BreadCrumps';
import Spinner from '../components/spinner';

const EventCardLazy = React.lazy(() => import('./acaGroups/EventCard')); 

const Aca_Groups = () => {
  useEffect(() => {
    document.title = "ACA Groups - Non Profit | Asian Christian Academy - Hosur";
  }, []);

  // Your session data
  const sessionData = [
    {
      id:0,
      imageUrl: "assets/images/groups/ETS-414-342.jpg",
      title: "Evangelical Theological Seminary",
      description: "Advancing God's unconditional grace through the equipping of men and women to be Christian leaders in India and Asia with post-graduate theological education.",
      phoneNumber: "04344255800",
      websiteUrl: "https://www.ets-india.org"
    },
    {
        id:1,
        imageUrl: "assets/images/groups/Agape-414-342.jpg",
        title: "Agape Hospital",
        description: "Advancing God's unconditional grace through compassionate medical care for Hosur and all surrounding Villages.",
        phoneNumber: "4344254521",
        websiteUrl: "https://www.agapehospital.org"
      },
      {
        id:2,
        imageUrl: "assets/images/groups/ACHS-414-342.jpg",
        title: "Asian Christian High School",
        description: "Providing high quality K to 12 education for Hosur taluk in the spirit of Christian love and service.",
        phoneNumber: "04344255800",
        websiteUrl: "https://www.achs-india.org"
      },
      {
        id:3,
        imageUrl: "assets/images/groups/ROH-414-342.jpg",
        title: "Ray of Hope Center for Autism",
        description: "The Ray of Hope center for Autism offers quality early intervention services to children with autism and related disabilities.",
        phoneNumber: "04344255800",
        websiteUrl: "https://rohautism.org"
      },
      {
        id:4,
        imageUrl: "assets/images/groups/ACCOT-414-342.jpg",
        title: "Asian Christian College of Theology",
        description: "Advancing God's unconditional grace through the equipping of men and women to be Christian leaders in India and Asia with graduate theological education.",
        phoneNumber: "04682972971",
        websiteUrl: "https://www.accot-india.org"
      },
      {
        id:5,
        imageUrl: "assets/images/groups/PTP-414-342.jpg",
        title: "Pastoral Training Program",
        description: "There are an estimated 95% of pastors in South Asia who have never had a theological education. While the church is growing quantitatively, this lack of training is hurting the health of the church. We are currently training 120 pastors in their mother tongue and plan on training at least 700 in the next 3 years. To train these pastors we need resources from as many who’d like to partner with ACA in building God’s kingdom.",
        phoneNumber: "",
        websiteUrl: ""
      },
      {
        id:6,
        imageUrl: "assets/images/groups/Hostel-414-342.jpg",
        title: "Student Hostel",
        description: "Advancing God's unconditional grace through a student hostel providing full care to destitute and underprivileged children through scholarship to become independent and contributing members of society.",
        phoneNumber: "",
        websiteUrl: ""
      },
      {
        id:7,
        imageUrl: "assets/images/groups/Media-414-342.jpg",
        title: "ACA Media",
        description: "The ACA Media School offer media courses providing technical excellence and professional training in various disciplines of media. We provide professional equipment and tools, and offer practical education. Students are mentored by certified and qualified professionals which helps students develop an in-depth understanding of the media industry and its multiple verticals.",
        phoneNumber: "04344255865",
        websiteUrl: "https:/media-aca.org"
      },
      {
        id:8,
        imageUrl: "assets/images/groups/ACCASI-414-342.jpg",
        title: "Asian Christian Culinary and Agricultural Science Institute (ACCASI)",
        description: "Asian Christian Culinary and Agricultural Science Institute (ACCASI) is India's first Farm-to-Table culinary institute providing vocational culinary education with training in organic small farming techniques",
        phoneNumber: "4344255876",
        websiteUrl: "https:/accasi.org"
      },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <BreadCrumps name="ACA Groups" title="ACA Groups" />
        <section className="events-list-page section-space">
          <div className="container">
            <div className="row gutter-y-30">
            <Suspense fallback={<Spinner />}>
                {sessionData.map((session, index) => (
                  <EventCardLazy
                    key={index}
                    id={session.id}
                    imageUrl={session.imageUrl}
                    title={session.title}
                    description={session.description}
                    phoneNumber={session.phoneNumber}
                    websiteUrl={session.websiteUrl}
                  />
                ))}
              </Suspense>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Aca_Groups;
