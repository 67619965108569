import React, { Suspense, lazy } from 'react';
import BreadCrumps from '../layout/BreadCrumps';
import Spinner from '../components/spinner';

// Lazy-loaded components
const MapSession = lazy(() => import('./contact/MapSession'));
const FormSession = lazy(() => import('./contact/FormSession'));

function Contact() {
  return (
    <div className="page-wrapper">
      <BreadCrumps name="Contact Us" title="Contact Us" />

      {/* Suspense to handle lazy loading */}
      <Suspense fallback={<Spinner />}>
        <section className="contact-one section-space @@extraClassName">
          <div className="container">
            <div className="row gutter-y-30">
              <MapSession />
              <FormSession />
            </div>
          </div>
        </section>
      </Suspense>
    </div>
  );
}

export default Contact;
