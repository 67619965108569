import React from 'react'
import config  from '../config';

function BreadCrumps(prps) {
  return (
    <div>     
    <section className="page-header @@extraClassName">
    <div className="page-header__bg"  style={{backgroundImage: `url('${config.baseUrl}/assets/images/backgrounds/page-header-bg-1-1.jpg')`}}></div>
    {/* <!-- /.page-header__bg --> */}
    <div className="container">
        <h2 className="page-header__title">{prps.title}</h2>
        <ul className="cleenhearts-breadcrumb list-unstyled">
            <li><i className="icon-home"></i> <a href="/">Home</a></li>
            <li><span>{prps.name}</span></li>
        </ul>
        {/* <!-- /.thm-breadcrumb list-unstyled --> */}
    </div>
    {/* <!-- /.container --> */}
    </section>
    </div>
  )
}

export default BreadCrumps