// Spinner.jsx

import React from 'react';
 // Import CSS file for styling

function Spinner() {
  return (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
      <style jsx>{`
      /* Spinner.css */

      .spinner-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Ensure spinner is above other content */
      }
      
      .spinner-container {
        width: 50px;
        height: 50px;
        position: relative;
      }
      
      .spinner {
        width: 50px;
        height: 50px;
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #3498db;
        animation: spin 1s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
      `}</style>
    </div>
  );
}

export default Spinner;
