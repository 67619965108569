import React, { Suspense, lazy } from 'react';
import BreadCrumps from '../layout/BreadCrumps';
import Spinner from '../components/spinner';

// Lazy-loaded components
const AboutSession = lazy(() => import('../pages/about/AboutSession'));
const HistorySession = lazy(() => import('../pages/about/HistorySession'));
const TeamSession = lazy(() => import('./about/TeamSession'));
const FaqSession = lazy(() => import('./about/FaqSession'));
const OurValuesSession = lazy(() => import('./about/OurValuesSession'));
const BecomeVolunteerSession = lazy(() => import('./about/BecomeVolunteerSession'));

function About() {
  return (
    <div className="page-wrapper">
      <BreadCrumps name="About Us" title="About Us" />

      {/* Suspense to handle lazy loading */}
      <Suspense fallback={<Spinner/>}>
        <AboutSession />
        <HistorySession />
        <TeamSession />
        <FaqSession />
        <OurValuesSession />
        <BecomeVolunteerSession />
      </Suspense>
    </div>
  );
}

export default About;
