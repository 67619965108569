import React , { useState, useEffect } from 'react'

function Footer() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 1000 * 60); // Update every minute to handle cases where the year changes while the user is on the page
        
        return () => clearInterval(intervalId); // Cleanup function
    }, []);
  return (
    <div>
           <footer className="main-footer background-white2">
            <div className="main-footer__top">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-xl-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                            <div className="footer-widget footer-widget--about">
                                <a href="index.html" className="footer-widget__logo">
                                    <img src="assets/images/logo-dark.png" width="159" alt="Cleenhearts HTML Template"/>
                                </a>
                                <p className="footer-widget__about-text">Wimply dummy text of the priatype industry orem Ipsum has Maecenas quis eros at ante</p>
                            </div>
                            {/* <!-- /.footer-widget --> */}
                            <a href="https://www.google.com/maps/@12.802449,77.856491,12z?entry=ttu" className="footer-widget__map" target='_blank'  rel="noopener noreferrer">
                                <span className="footer-widget__map__text">View Map</span>
                                <span className="icon-paper-plane"></span>
                            </a>
                        </div>
                        {/* <!-- /.col-md-12 col-xl-3 --> */}
                        <div className="col-xl-3 col-md-5 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="footer-widget footer-widget--contact">
                                <h2 className="footer-widget__title">Get in touch!</h2>
                                {/* <!-- /.footer-widget__title --> */}
                                <ul className="list-unstyled footer-widget__info">
                                    <li> <span className="icon-location"></span>
                                        <address>Asian Christian Academy, Jeemangalam, Bagalur (P.O), Hosur, Krishnagiri (Dist.), Tamil Nadu – 635103,India</address>
                                    </li>
                                    <li> <span className="icon-phone"></span><a href="tel:04344255800">04344255800</a></li>
                                    <li> <span className="icon-envelope"></span><a href="mailto:management@acaindia.org">management@acaindia.org</a></li>
                                </ul>
                                {/* <!-- /.list-unstyled --> */}
                            </div>
                            {/* <!-- /.footer-widget --> */}
                        </div>
                        {/* <!-- /.col-xl-3 col-md-5 --> */}
                        <div className="col-md-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="footer-widget footer-widget--links">
                                <h2 className="footer-widget__title">Quick Links</h2>
                                {/* <!-- /.footer-widget__title --> */}
                                <ul className="list-unstyled footer-widget__links">
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="https://give.cornerstone.cc/acaindia"  target='_blank'  rel="noopener noreferrer">Give Donation</a></li>
                                    {/* <li><a href="donate-right.html">Education Support</a></li> */}
                                    {/* <li><a href="events-list.html">Our Campaign</a></li> */}
                                    <li><a href="/contact">Contact Us</a></li>
                                </ul>
                                {/* <!-- /.list-unstyled footer-widget__links --> */}
                            </div>
                            {/* <!-- /.footer-widget --> */}
                        </div>
                        {/* <!-- /.col-md-3 --> */}
                        <div className="col-md-4 col-xl-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                            <div className="footer-widget footer-widget--gallery">
                                <h2 className="footer-widget__title">Our Gallery</h2>
                                {/* <!-- /.footer-widget__title --> */}
                                <div className="footer-widget__gallery">
                                    <a href="gallery.html" className="footer-widget__gallery__link">
                                        <img src="assets/images/gallery/footer-widget-gallery-img-1.jpg" alt="footer-widget-gallery" />
                                        <span className="footer-widget__gallery__icon icon-plus"></span>
                                    </a>
                                    {/* <!-- /.footer-widget__gallery__link --> */}
                                    <a href="gallery.html" className="footer-widget__gallery__link">
                                        <img src="assets/images/gallery/footer-widget-gallery-img-2.jpg" alt="footer-widget-gallery"/>
                                        <span className="footer-widget__gallery__icon icon-plus"></span>
                                    </a>
                                    {/* <!-- /.footer-widget__gallery__link --> */}
                                    <a href="gallery.html" className="footer-widget__gallery__link">
                                        <img src="assets/images/gallery/footer-widget-gallery-img-3.jpg" alt="footer-widget-gallery"/>
                                        <span className="footer-widget__gallery__icon icon-plus"></span>
                                    </a>
                                    {/* <!-- /.footer-widget__gallery__link --> */}
                                    <a href="gallery.html" className="footer-widget__gallery__link">
                                        <img src="assets/images/gallery/footer-widget-gallery-img-4.jpg" alt="footer-widget-gallery"/>
                                        <span className="footer-widget__gallery__icon icon-plus"></span>
                                    </a>
                                    {/* <!-- /.footer-widget__gallery__link --> */}
                                    <a href="gallery.html" className="footer-widget__gallery__link">
                                        <img src="assets/images/gallery/footer-widget-gallery-img-5.jpg" alt="footer-widget-gallery"/>
                                        <span className="footer-widget__gallery__icon icon-plus"></span>
                                    </a>
                                    {/* <!-- /.footer-widget__gallery__link --> */}
                                    <a href="gallery.html" className="footer-widget__gallery__link">
                                        <img src="assets/images/gallery/footer-widget-gallery-img-6.jpg" alt="footer-widget-gallery"/>
                                        <span className="footer-widget__gallery__icon icon-plus"></span>
                                    </a>
                                    {/* <!-- /.footer-widget__gallery__link --> */}
                                </div>
                                {/* <!-- /.footer-widget__gallery --> */}
                            </div>
                            {/* <!-- /.footer-widget --> */}
                        </div>
                        {/* <!-- /.col-md-4 col-xl-3 --> */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.container --> */}
                <div className="main-footer__top__shape-box">
                    <img src="assets/images/shapes/footer-shape-hand-1-1.png" alt="hand" className="main-footer__top__shape-one"/>
                    <img src="assets/images/shapes/footer-shape-hand-1-2.png" alt="hand" className="main-footer__top__shape-two"/>
                </div>
                {/* <!-- /.main-footer__top__shape-box --> */}
            </div>
            {/* <!-- /.main-footer__top --> */}
            <div className="main-footer__bottom">
            <div className="main-footer__bottom__bg" style={{background: `url('assets/images/backgrounds/footer-bottom-bg-1-2.png')`}}></div>
                {/* <!-- /.main-footer__bottom-bg --> */}
                <div className="container">
                    <div className="main-footer__bottom__inner">
                        <p className="main-footer__copyright">
                            &copy; Copyright <span className="dynamic-year">{currentYear}</span> <a href='https://media-aca.org/'  target='_blank'  rel="noopener noreferrer">ACA Media</a> All Rights Reserved.
                        </p>
                    </div>
                    {/* <!-- /.main-footer__inner --> */}
                </div>
                {/* <!-- /.container --> */}
            </div>
            {/* <!-- /.main-footer__bottom --> */}
        </footer>
    </div>
  )
}

export default Footer