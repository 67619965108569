import React , { useState, useEffect }  from 'react'
import config  from '../config';

function Header() {
    const pathName = window.location.pathname;

    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const toggleMobileNav = () => {
        console.log(isMobileNavOpen);
        setIsMobileNavOpen(!isMobileNavOpen);
        document.body.classList.toggle("locked"); 
    }

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [clicking, setClicking] = useState(false);
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    const cursor = document.querySelector(".custom-cursor__cursor");
    const cursorinner = document.querySelector(".custom-cursor__cursor-two");

    function handleMouseMove(e) {
      const x = e.clientX;
      const y = e.clientY;
      setCursorPosition({ x, y });
    }

    function handleMouseDown() {
      setClicking(true);
      cursor.classList.add("click");
      cursorinner.classList.add("custom-cursor__innerhover");
    }

    function handleMouseUp() {
      setClicking(false);
      cursor.classList.remove("click");
      cursorinner.classList.remove("custom-cursor__innerhover");
    }

    function handleMouseOver() {
      setHovering(true);
      cursor.classList.add("custom-cursor__hover");
    }

    function handleMouseLeave() {
      setHovering(false);
      cursor.classList.remove("custom-cursor__hover");
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    const links = document.querySelectorAll("a");
    links.forEach(item => {
      item.addEventListener("mouseover", handleMouseOver);
      item.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      links.forEach(item => {
        item.removeEventListener("mouseover", handleMouseOver);
        item.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);


  return (
    <div className="custom-cursor">
    <div className="custom-cursor__cursor" style={{ transform: `translate3d(calc(${cursorPosition.x}px - 50%), calc(${cursorPosition.y}px - 50%), 0)` }} />
    <div className="custom-cursor__cursor-two" style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px` }} />


         <header className="main-header sticky-header sticky-header--normal">
            <div className="container-fluid">
                <div className="main-header__inner">
                    <div className="main-header__logo">
                        <a href="/">
                        <img src={`${config.baseUrl}/assets/images/logo-dark.png`} alt="Cleenhearts HTML" width="159" />
                        </a>
                        {/* <button type="button" className="main-header__sidebar-btn sidebar-btn__toggler">
                            <span className="icon-grid"></span>
                        </button> */}
                        {/* <!-- /.main-header__sidebar-btn --> */}
                    </div>
                    {/* <!-- /.main-header__logo --> */}
                    <div className="main-header__right">
                        <nav className="main-header__nav main-menu">
                            <ul className="main-menu__list">
                                <li className={` ${pathName === '/' ? 'current' : ''}`}>
                                    <a href="/">Home</a>
     
                                </li>

                                <li className={` ${pathName === '/about' ? 'current' : ''}`}>
                                    <a href="/about">About</a>
                                </li>
                                <li className={` ${pathName === '/acaGroups' || pathName === '/acaGroupsDetails' ? 'current' : ''}`}>
                                    <a href="/acaGroups">ACA Groups</a>
                                </li>
                                <li>
                                    <a href="https://acaindia.keka.com/careers" target='_blank'  rel="noopener noreferrer">Career</a>
                                </li>
                                <li className={` ${pathName === '/contact' ? 'current' : ''}`}>
                                    <a href="/contact">Contact Us</a>
                                </li>
                            </ul>
                        </nav>
                        {/* <!-- /.main-header__nav --> */}
                        <div className="mobile-nav__btn mobile-nav__toggler" onClick={toggleMobileNav}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        {/* <!-- /.mobile-nav__toggler --> */}
                        <div className="main-header__cart">
                            {/* <a href="cart.html" className="main-header__cart__link">
                                <span className="icon-trolley"></span>
                            </a>
                            <span className="main-header__cart__quantity">0</span> */}
                        </div>
                        {/* <!-- /.main-header__cart --> */}
                        <a href="https://give.cornerstone.cc/acaindia" target='_blank'  rel="noopener noreferrer" className="cleenhearts-btn main-header__btn">
                            <div className="cleenhearts-btn__icon-box">
                                <div className="cleenhearts-btn__icon-box__inner"><span className="icon-donate"></span></div>
                            </div>
                            <span className="cleenhearts-btn__text">donate now</span>
                        </a>
                        {/* <!-- /.thm-btn main-header__btn --> */}
                    </div>
                    {/* <!-- /.main-header__right --> */}
                </div>
                {/* <!-- /.main-header__inner --> */}
            </div>
            {/* <!-- /.container --> */}
        </header>
        <div className={`mobile-nav__wrapper ${isMobileNavOpen ? 'expanded' : ''}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={toggleMobileNav}></div>
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler" onClick={toggleMobileNav}><i className="fa fa-times"></i></span>
                    <div className="logo-box">
                        <a href="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="155" alt="" /></a>
                    </div>
            {/* <!-- /.logo-box --> */}
            <div className="mobile-nav__container">
            <ul className="main-menu__list">
                                <li>
                                    <a href="/">Home</a>
     
                                </li>

                                <li>
                                    <a href="/about">About</a>
                                </li>
                                <li>
                                    <a href="/acaGroups">ACA Groups</a>
                                </li>
                                <li>
                                    <a href="https://acaindia.keka.com/careers" target='_blank'  rel="noopener noreferrer">Career</a>
                                </li>
                                <li>
                                    <a href="/contact">Contact Us</a>
                                </li>
                            </ul>
            </div>
            {/* <!-- /.mobile-nav__container --> */}

            <ul className="mobile-nav__contact list-unstyled">
                <li>
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:management@acaindia.org">management@acaindia.org</a>
                </li>
                <li>
                    <i className="fa fa-phone-alt"></i>
                    <a href="tel:04344255800">04344255800</a>
                </li>
            </ul>
            {/* <!-- /.mobile-nav__contact --> */}
            <div className="mobile-nav__social">
                <a href="https://www.facebook.com/AsianChristianAcademy/"  rel="noopener noreferrer" target='_blank'>
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    <span className="sr-only">Facebook</span>
                </a>
                <a href="https://www.instagram.com/asianchristianacademy/"  rel="noopener noreferrer" target='_blank'>
                    <i className="fab fa-instagram" aria-hidden="true"></i>
                    <span className="sr-only">Instagram</span>
                </a>
                <a href="https://twitter.com/ACAIndia" rel="noopener noreferrer" target='_blank'>
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                    <span className="sr-only">Twitter</span>
                </a>
                <a href="https://www.linkedin.com/company/acaindia/" aria-hidden="true"  rel="noopener noreferrer" target='_blank'>
                    <i className="fab fa-linkedin-in"></i>
                    <span className="sr-only">Linkedin</span>
                </a>
                <a href="https://www.youtube.com/@aca-india" aria-hidden="true"  rel="noopener noreferrer" target='_blank'>
                    <i className="fab fa-youtube"></i>
                    <span className="sr-only">Youtube</span>
                </a>
            </div>
            {/* <!-- /.mobile-nav__social --> */}
        </div>
        {/* <!-- /.mobile-nav__content --> */}
    </div>
    {/* <!-- /.mobile-nav__wrapper --> */}
    
    <div className="search-popup @@extraClassName">
        <div className="search-popup__overlay search-toggler"></div>
        {/* <!-- /.search-popup__overlay --> */}
        <div className="search-popup__content">
            <form role="search" method="get" className="search-popup__form" action="#">
                <input type="text" id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" className="cleenhearts-btn">
                    <span><i className="icon-search"></i></span>
                </button>
            </form>
        </div>
        {/* <!-- /.search-popup__content --> */}
    </div>
    {/* <!-- /.search-popup --> */}

    <a href="#top" data-target="html" className="scroll-to-target scroll-to-top">
    <span className="scroll-to-top__text">back top</span>
    <span className="scroll-to-top__wrapper"><span className="scroll-to-top__inner"></span></span>
    </a>


    <aside className="sidebar-one">
        <div className="sidebar-one__overlay sidebar-btn__toggler"></div>
        {/* <!-- /.siderbar-ovarlay --> */}
        <div className="sidebar-one__content">
            <span className="sidebar-one__close sidebar-btn__toggler"><i className="fa fa-times"></i></span>
            <div className="sidebar-one__logo sidebar-one__item">
                <a href="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="155" alt="logo-dark" /></a>
            </div>
            {/* <!-- /.sidebar-one__logo --> */}
            <div className="sidebar-one__about sidebar-one__item">
                <p className="sidebar-one__about__text">Wimply dummy text of the priatype industry orem Ipsum has Maecenas quis eros at ante</p>
            </div>
            {/* <!-- /.sidebar-one__about --> */}
            <div className="sidebar-one__information sidebar-one__item">
                <h4 className="sidebar-one__title">Information</h4>
                <ul className="sidebar-one__information__list">
                    <li>
                        <span className="sidebar-one__information__icon icon-location"></span>
                        <address className="sidebar-one__information__address">
                            <p className="sidebar-one__information__text">85 Ketch Harbour RoadBensalem, PA 19020</p>
                        </address>
                    </li>
                    <li>
                        <i className="sidebar-one__information__icon icon-envelope"></i>
                        <p className="sidebar-one__information__text"><a href="mailto:needhelp@company.com">needhelp@company.com</a></p>
                    </li>
                    <li>
                        <span className="sidebar-one__information__icon icon-phone"></span>
                        <p className="sidebar-one__information__text"><a href="tel:(406)555-0120">(406) 555-0120</a></p>
                    </li>
                </ul>
            </div>
            {/* <!-- /.sidebar-one__information --> */}
            <div className="sidebar-one__socila social-link sidebar-one__item">
                <a href="https://facebook.com/">
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    <span className="sr-only">Facebook</span>
                </a>
                <a href="https://twitter.com/">
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                    <span className="sr-only">Twitter</span>
                </a>
                <a href="https://linkedin.com/" aria-hidden="true">
                    <i className="fab fa-linkedin-in"></i>
                    <span className="sr-only">Linkedin</span>
                </a>
                <a href="https://youtube.com/" aria-hidden="true">
                    <i className="fab fa-youtube"></i>
                    <span className="sr-only">Youtube</span>
                </a>
            </div>
            {/* <!-- /sidebar-one__socila --> */}
            <div className="sidebar-one__newsletter sidebar-one__item">
                <label className="sidebar-one__title" htmlFor="sidebar-email">Newsletter Subscribe</label>
                <form action="#" className="sidebar-one__newsletter__inner mc-form" data-url="MAILCHIMP_FORM_URL">
                    <input type="email" name="sidebar-email" id="sidebar-email" className="sidebar-one__newsletter__input" placeholder="Email Address"/>
                    <button type="submit" className="sidebar-one__newsletter__btn"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                </form>
                <div className="mc-form__response"></div>
                {/* <!-- /.mc-form__response --> */}
            </div>
            {/* <!-- /.sidebar-one__form --> */}
        </div>
        {/* <!-- /.sidebar__content --> */}
    </aside>
     
    </div>
  )
}

export default Header