import React from 'react'

function topBar() {
  return (
    <div>
          <div className="topbar-one">
            <div className="container-fluid">
                <div className="topbar-one__inner">
                    <ul className="list-unstyled topbar-one__info">
                        <li className="topbar-one__info__item">
                            <span className="topbar-one__info__icon icon-paper-plane"></span>
                            <a href="mailto:management@acaindia.org">management@acaindia.org</a>
                        </li>
                        <li className="topbar-one__info__item">
                            <span className="topbar-one__info__icon icon-location"></span>
                            Asian Christian Academy, Jeemangalam, Bagalur (P.O), Hosur, Krishnagiri (Dist.), Tamil Nadu – 635103,India
                        </li>
                    </ul>
                    {/* <!-- /.list-unstyled topbar-one__info --> */}
                    <div className="topbar-one__right">
                        <div className="social-link topbar-one__social">
                            <a href="https://www.facebook.com/AsianChristianAcademy/"  rel="noopener noreferrer" target='_blank'>
                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                <span className="sr-only">Facebook</span>
                            </a>
                            <a href="https://www.instagram.com/asianchristianacademy/"  rel="noopener noreferrer" target='_blank'>
                                <i className="fab fa-instagram" aria-hidden="true"></i>
                                <span className="sr-only">Instagram</span>
                            </a>
                            <a href="https://twitter.com/ACAIndia"  rel="noopener noreferrer" target='_blank'>
                                <i className="fab fa-twitter" aria-hidden="true"></i>
                                <span className="sr-only">Twitter</span>
                            </a>
                            <a href="https://www.linkedin.com/company/acaindia/" aria-hidden="true"  rel="noopener noreferrer" target='_blank'>
                                <i className="fab fa-linkedin-in"></i>
                                <span className="sr-only">Linkedin</span>
                            </a>
                            <a href="https://www.youtube.com/@aca-india" aria-hidden="true"  rel="noopener noreferrer" target='_blank'>
                                <i className="fab fa-youtube"></i>
                                <span className="sr-only">Youtube</span>
                            </a>
                        </div>
                        {/* <!-- /.topbar-one__social --> */}
                    </div>
                    {/* <!-- /.topbar-one__right --> */}
                </div>
                {/* <!-- /.topbar-one__inner --> */}
            </div>
            {/* <!-- /.container --> */}
        </div>
    </div>
  )
}

export default topBar