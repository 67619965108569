import React from 'react'

function NotFoundPage() {
  return (
    <div>
         <div className="page-wrapper">
         <section class="error-404 section-space">
            <div class="container">
                <div class="error-404__content">
                    <div class="error-404__content__top">
                        <img src="assets/images/error/error-image-1-1.png" alt="error" class="error-404__image" />
                        <h2 class="error-404__title error-404__title--one">4</h2>
                        <h2 class="error-404__title error-404__title--two">0</h2>
                        <h2 class="error-404__title error-404__title--three">4</h2>
                    </div>
                    <h3 class="sec-title__title error-404__sub-title wow fadeInUp" data-wow-duration="1500ms">Sorry we can't find that page you’re looking for.</h3>
                    <a href="/" class="cleenhearts-btn wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div class="cleenhearts-btn__icon-box">
                            <div class="cleenhearts-btn__icon-box__inner"><span class="icon-duble-arrow"></span></div>
                        </div>
                        <span class="cleenhearts-btn__text">back to home</span>
                    </a>
                </div>
            </div>
        </section>

         </div>
    </div>
  )
}

export default NotFoundPage