import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import About from './pages/about';
import AcaGroups from './pages/Aca_Groups';
import AcaGroupsDetails from './pages/innerPages/Groups_details';
import Contact from './pages/contact';
import Header from './layout/header';
import Footer from './layout/footer';
import Topbar from './layout/topBar';
import NotFoundPage from './pages/404';

function App() {
  return (
    <div>
      <Topbar />
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/acaGroups" element={<AcaGroups />} />
          {/* Define route with parameter */}
          <Route path="/acaGroupsDetails/:id" element={<AcaGroupsDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
