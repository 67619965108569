import React, { Suspense } from 'react';
// import OwlCarouselComponent from '../components/MainPageCarosel';
import Spinner from '../components/spinner';

// Lazy-loaded components
const OwlCarouselComponent = React.lazy(() => import('../components/MainPageCarosel'));
const HomeAboutSession = React.lazy(() => import('./home/HomeAboutSession'));
const DonateScrollingSession = React.lazy(() => import('./home/DonateScrollingSession'));
const InspiringSession = React.lazy(() => import('./home/InspiringSession'));
const UpComingEventsSession = React.lazy(() => import('./home/UpComingEventsSession'));
const BolgSession = React.lazy(() => import('./home/BolgSession'));
const TestimonialsSession = React.lazy(() => import('./home/TestimonialsSession'));
const OrganisationsSession = React.lazy(() => import('./home/OrganisationsSession'));

function Index() {
  return (
    <div className="page-wrapper">
   
      {/* Suspense to handle lazy loading */}
      <Suspense fallback={<Spinner />} jsx={true.toString()}>
      <OwlCarouselComponent />
        <HomeAboutSession />
        <DonateScrollingSession />
        <InspiringSession />
        <UpComingEventsSession />
        <BolgSession />
        <TestimonialsSession />
        <OrganisationsSession />
      </Suspense>
    </div>
  );
}

export default Index;
